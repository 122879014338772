'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.controller:ReportDataCtrl

 # @description

###
class ReportDataCtrl
  ### @ngInject ###
  constructor:(
    $compile
    $sce
    $rootScope
    $scope
    $log
    $window
    $document
    $timeout
    $q
    $templateRequest
    $filter
    $translate
    $mdDialog
    $anchorScroll
    ReportConfigurationManager
    ServiceReportVisualizeManager
    ServiceReportVisualizeDownloadManager
    ReportMapService
    ColorService
    ReportConfigurationService
    ReportFilterService
    ReportFieldService
    ReportSchedulingService
    ReportTemplateManager
    FileSaver
    Restangular
    UserPermissions
    UnitPermissions
    _
    uuid4
    MundoMap
    MundoSettings
    MundoActions
    RestUtils
    UiHelpers
    moment
    toastr
    MundoNavigation
  ) ->

    # Getting the openLayers
    ol = $window.ol

    Mustache = $window.Mustache

    Mustache.Formatters = {
      "add": (one, two) ->
        return $filter('number')(one + two, 2)
      "divide": (one, two) ->
        return $filter('number')(one / two, 2)
      "substract": (one, two) ->
        return $filter('number')(one - two, 2)
      "multiply": (one, two) ->
        return $filter('number')(one * two, 2)
      "default": (one, two) ->
        return (if one? then one else two)
      "translate": (one) ->
        return $filter('translate')(one)
      "datetime": (one) ->
        return $filter('amDateFormat')($filter('amLocal')($filter('amParse')(one)), 'DD-MM-YYYY HH:mm:ss')
    }

    DownloadState =
      IDLE: 'IDLE'
      ACTIVE: 'ACTIVE'

    ViewMode =
      TABLE: 'TABLE'
      MAP: 'MAP'

    $scope.DownloadState = DownloadState
    $scope.ViewMode = ViewMode

    # Bind resize handler
    angular.element($window).bind 'resize', () =>
      @onResize()

    $rootScope.$on 'navigation::toggle', () =>
      @onResize()

    # Reset seed_colors while switching Ctrls
    ColorService.resetSeedColors()

    # When Tab is initiated this is called.
    @init = (source) =>
      if @initiated
        return

      # Only allow calling this method once
      @initiated = true

      @downloadState = DownloadState.IDLE
      @viewMode = ViewMode.TABLE
      @isLoadingData = false
      @reachedEndOfReportItems = false
      @source = source
      $log.debug @source
      @reportSources = $scope.$parent.$parent.reportingCtrl.reportSources
      @visualizers = if @source.source.visualizers? then _.values(@source.source.visualizers) else {}

      @sidebarVisible = true

      @toggleSidebar = toggleSidebar

      ## Functions that were replaced with services

      # Filter service
      @getReportFilters = ReportFilterService.getReportFilters
      @selectFilters = ReportFilterService.selectFilters
      @getFilterCategories = ReportFilterService.getFilterCategories

      # Scheduling service
      @selectScheduling = ReportSchedulingService.selectScheduling

      # Map service
      @setMapHeight = ReportMapService.setMapHeight

      # Determine list of mappable fields
      @mappableFields = _.values @source.source.fields
        .filter (f) ->
          return f.options? and f.options.mappable? and f.options.mappable
      @mappable = @mappableFields.length > 0

      # Determine list of mappable links
      @mappableLinks = _.values @source.source.links
        .filter (l) ->
          return l.options? and l.options.mappable? and l.options.mappable

      # Mark linked fields as such
      for link in _.values @source.source.links
        display_field_present = false
        data_field_present = false

        data_field = link.field
        display_field = if link.options? and link.options.display_field? then link.options.display_field else data_field

        for field in _.values @source.source.fields
          field.links = []
          if field.code == display_field
            field.links.push link
            break

        # # Store source and configuration in link
        # linkSource = @reportSources.filter (v) -> v.code == link.code
        # linkSource = if linkSource.length then linkSource[0] else null
        # link.active.source = linkSource

      # Initialize map view
      @initializeMapView()

      @table =
        pageLimit: 25
        pageLimitOptions: [25, 50, 100]
        pageNumber: 1
        pageCount: 2
        recordOffset: 0
        recordCount: 25
        orderField: null
        orderDirection: true

      #when optional parameters are passed from higher up
      if @source.optParams?
        for param in @source.optParams
          if param.offset?
            @table.recordOffset = param.offset
          if param.limit?
            @table.pageLimit = param.limit

      $q.all([
        @loadDefaultFields()
        @loadDefaultFilters()
      ]).then () =>
        @initializeFields()
        @getData source, null, true

    # Handle a resize event properly
    @onResize = () =>
      # Fix table height
      if @viewMode == ViewMode.TABLE
        @setTableHeight()
      else if @viewMode == ViewMode.MAP
        @setMapHeight(@map)

    # Change between views (map, table, etc..)
    @setViewMode = (mode, save = true) =>
      @viewMode = mode

      if save
        @saveViewDetails()
      if @viewMode == ViewMode.MAP
        @onMapViewMode()
      else
        @mappableLinksLoaded = false

      @onResize()

    # Store the active view mode, along with
    # some view mode details
    @saveViewDetails = () =>
      @setUserDataValue 'viewMode', @viewMode
      @saveUserData()

    #######################
    ## User data storage ##
    #######################
    @setUserDataValue = (key, value, cfg = @source.configuration) =>
      userData = @getUserData(cfg)
      userData[key] = value

    @getUserDataValue = (key, defaultValue = null, cfg = @source.configuration) =>
      userData = @getUserData(cfg)

      if userData[key]?
        return userData[key]

      return defaultValue

    @saveUserData = (cfg = @source.configuration) =>

      userData = @getUserData(cfg)
      userDataString = JSON.stringify(userData)

      return $q (resolve, reject) ->
        ReportConfigurationService
          .patchConfiguration cfg,
            userData: userDataString
          .then () ->
            $scope.$emit 'replaceConfigWithUpdatedConfig',
              config: cfg

            resolve()
          , () ->
            reject()

    @getUserData = (cfg = @source.configuration) =>
      if not cfg.userData?
        cfg.userData = {}

      if not angular.isObject(cfg.userData)
        cfg.userData = JSON.parse(cfg.userData)

      return cfg.userData

    ################
    # Map-specific #
    ################

    @onMapViewMode = () =>
      if not @map?
        @initializeMapView()
      else
        @map.updateSize()
        @loadMappableLinks(true)
      $timeout () =>
        @map.updateSize()
        @loadMappableLinks()
        MundoMap.updateMapView @map
      , 500

    @initializeMapView = () =>
      # Set the id of the current map
      @mapId = "map-#{uuid4.generate()}"

      # Wait for DOM render
      $timeout () =>

        ## Get a new map from the map service
        @map = ReportMapService.getMap @mapId

        ## Get layers and put them in variables
        @markerLayer = MundoMap.getLayerById @map, 'markers'
        @markerLayerSource = MundoMap.getLayerSource @markerLayer

        @linkLayer = MundoMap.getLayerById @map, 'links'
        @linkLayerSource = MundoMap.getLayerSource @linkLayer

        @embeddedLinkLayer = MundoMap.getLayerById @map, 'links_embedded'
        @embeddedLinkLayerSource = MundoMap.getLayerSource @embeddedLinkLayer

        @heatmapLayer = MundoMap.getLayerById @map, 'heatmap'
        @heatmapLayer.setRadius 3
        @heatmapLayerSource = MundoMap.getLayerSource @heatmapLayer

        @linkLayer.setStyle (feature) ->
          ReportMapService.getLinkLayerStyle feature

        @markerLayer.setStyle (feature) ->
          ReportMapService.getMarkerLayerStyle feature

        @embeddedLinkLayer.setStyle (feature) ->
          ReportMapService.getEmbeddedLinkLayerStyle feature

        @popupOverlay = new ol.Overlay.Popup({insertFirstLot: false})
        @popupOverlay.autoSize = true
        @popupOverlay.panMapIfOutOfView = true
        @map.addOverlay @popupOverlay

        @selectFeatureOnMapInteraction = new ol.interaction.Select
          condition: ol.events.condition.click
          layers: [@markerLayer, @embeddedLinkLayer]
          style: (feature) ->
            style = feature.get '_cachedStyle'

        @map.addInteraction @selectFeatureOnMapInteraction
        @selectFeatureOnMapInteraction.on 'select', (e) =>
          if e.selected.length > 0
            layerProps = e.target.getLayer(e.selected[0]).getProperties()

            if layerProps._layerId in ['markers', 'links_embedded']
              @showFeaturePopup e.selected[0]
              @scrollToActiveListItem()

            # Deselect features for reselect directly
            @selectFeatureOnMapInteraction.getFeatures().clear()

        layers = @map.getLayers()

        @map.on 'moveend' , () =>
          @setUserDataValue 'zoomLevel', @map.getView().getZoom()
          @setUserDataValue 'mapCenter', @map.getView().getCenter()

        angular.forEach layers, (layer) =>
          layer.on 'change:visible', (event) =>
            @saveVisibleLayers()

    @saveVisibleLayers = () =>
      visibleLayers = MundoMap.getVisibleLayerIds @map
      @setUserDataValue 'mapLayers', visibleLayers
      @saveUserData()

    @showFeaturePopup = (feature, index) =>

      if (not feature?) and index? and @selectedFeature?
        feature = @selectedFeature.get('features')[index]

      @selectedFeature = feature
      features = if feature.get('features') then feature.get('features') else [feature]
      size = features.length
      selectedIndex = $scope.$parent.$parent.selectedIndex

      popupId = "#{@mapId}-popup"
      popupHTML = "<div id='#{popupId}'>"

      if size == 1
        feature = features[0]
        @selectedFeature = feature

        # If no report source type is present in the report record
        # metadata, assume it's a record for the currently opened (main)
        # report and use the table fields instead of the source fields
        sourceCode = feature.get('_item')._meta.source
        if not sourceCode?
          @selectedFeatureFields = @fields
        else
          # It's safe to assume the report source with the
          # specified code will be available, since we wouldn't
          # have been able to create a feature otherwise
          source = @reportSources.filter (v) -> v.code == sourceCode
          source = if source.length then source[0] else null
          @selectedFeatureFields = @getSourceFields(source)

        # coffeelint: disable=max_line_length
        popupHTML += "\
          <div id='marker-popup' ng-repeat='field in ::reportDataCtrl.selectedFeatureFields' >\
            <dl class='no-margin'>\
              <dt>{{ (('messages.report-table-header.' + field.label) | translate) }}</dt>\
              <dd ng-include ng-init=\"::reportItem=reportDataCtrl.selectedFeature.get('_item')\" src='::reportDataCtrl.getFieldTemplatePath(field)'></dd>\
            </dl>\
          </div>"
        # coffeelint: enable=max_line_length
      else
        # coffeelint: disable=max_line_length
        angular.forEach features, (feature, key) ->
          # truncated = $filter('truncate')(subFeature.get('_description'), 32, '..')
          popupHTML += "<div>\
            <md-button class='text-mono md-primary md-raised' ng-click='reportDataCtrl.showFeaturePopup(null, "+key.toString()+");reportDataCtrl.scrollToActiveListItem()'>\
              <strong>" + (key + 1).toString() + "</strong> \
              <i class='fa fa-angle-double-right'></i> \
              #{feature.get('_description')} \
              <md-tooltip>#{feature.get('_description')}</md-tooltip>\
            </md-button>\
          </div>"
        # coffeelint: enable=max_line_length

      popupHTML += "</div>"
      @popupOverlay.show feature.getGeometry().getCoordinates(), popupHTML

      pp = angular.element(document.querySelector("##{popupId}"))
      $timeout () ->
        $scope.$apply () ->
          $compile(pp)($scope)
      , 0
      return true

    @focusOnFeatures = (features, index = 0) =>
      MundoMap.panToFeatures @map, features
      feature = features[0]
      $timeout () =>
        @showFeaturePopup feature, index
      , 480

    @scrollToActiveListItem = () =>
      id = @selectedFeature.get('name')
      $anchorScroll(id)

    # Set the subreport filters
    @selectMapLinkFilters = (link) =>
      source = @reportSources.filter (v) -> v.code == link.code
      source = if source.length then source[0] else null

      cfgLink = @source.configuration.links.filter (v) -> v.code == link.code
      cfgLink = if cfgLink.length then cfgLink[0] else null

      if not (source and cfgLink and cfgLink.configuration)
        return

      configuration = cfgLink.configuration

      @selectFilters source, configuration
      .then () =>
        ReportConfigurationService.getReportConfiguration configuration['_id']
        .then (result) =>
          cfgLink.configuration = result
          @reloadMapLinkWaypoints()

    @toggleMapLinkWaypoints = (link) =>
      @reloadMapLinkWaypoints()

    @reloadMapLinkWaypoints = () =>
      @embeddedLinkLayerSource.clear()

      for link in @mappableLinks
        linkCfg = @source.configuration.links.filter (v) -> link.code == v.code
        linkCfg = if linkCfg.length then linkCfg[0] else null
        configuration = linkCfg.configuration

        if not configuration?
          continue

        source = @reportSources.filter (v) -> v.code == configuration.source
        source = if source.length then source[0] else null

        # Get the mappable fields for the source
        # NB: we currently only support geo_points visualized as linestrings
        mappableFields = _.values source.fields
          .filter (f) ->
            return f.options? and f.options.mappable? and f.options.mappable and f.type == 'geo_point'

        # If we don't have mappable fields, do nothing
        if not mappableFields.length
          continue

        angular.forEach @reportItems, (reportItem) =>
          reportItem._meta.embeddedMapMarkerFeatures = []
          reportItem._meta.embeddedMapMarkerFeaturesCount = 0
          reportItem._meta.embeddedMapMarkerFeaturesMaxCount = 100

          if not (reportItem._meta.embed? and reportItem._meta.embed[link.code])
            return

          linkValue = @getFieldData(link.field, reportItem)

          if not linkValue?
            return

          params =
            visualizer: 'JSON'
            configuration: configuration['_id']
            'filter[]': "#{link.filter},#{linkValue}"

          # Load filtered dataset, adding markers to the map
          # NOTE: We show a maximum of N entries to the user.
          # To tell them when more than N entries are available,
          # we actually fetch N + 1 entries instead.
          params.limit = reportItem._meta.embeddedMapMarkerFeaturesMaxCount + 1

          # We bypass field filters in order to retrieve all information
          # we want to visualize, not just the selected table columns
          params.bypassFields = true

          ServiceReportVisualizeManager
            .getList params
            .then (results) =>
              reportItem._meta.embeddedMapMarkerFeaturesCount = results.length

              # If we found too many results, remove the excess results from the resultset
              if reportItem._meta.embeddedMapMarkerFeaturesCount > reportItem._meta.embeddedMapMarkerFeaturesMaxCount
                results.pop()

              for res, key in results
                res._meta =
                  color: reportItem._meta.color
                  mapMarkerUrl: reportItem._meta.mapMarkerUrl
                  index: key
                  source: configuration.source

                # Iterate over mappable fields and try to get location data
                for field in mappableFields
                  data = @getFieldData field.field, res

                  if not data?
                    continue

                  label = @formatStringAsTemplate field.options.label, res
                  description = @formatStringAsTemplate field.options.description, res

                  # If we have location data, add a marker
                  geometry = MundoMap.getGeometryFromGeoJSON
                    type: 'Point'
                    coordinates: data.coordinates
                  feature = new ol.Feature
                    geometry: geometry
                    _item: res
                    _label: label
                    _description: description
                  # feature.setProperties
                  #   _color: "##{reportItem._meta.color}"
                  @embeddedLinkLayerSource.addFeature feature

                  # Store feature in report item
                  reportItem._meta.embeddedMapMarkerFeatures.push feature

    @loadMappableLinks = (force) =>
      if @mappableLinksLoaded and not force
        return
      #@reloadMapLinkWaypoints()

      @mappableLinksLoaded = true
      promises = []

      for link in @mappableLinks
        linkCfg = @source.configuration.links.filter (v) -> link.code == v.code
        linkCfg = if linkCfg.length then linkCfg[0] else null

        if not linkCfg?
          continue

        deferred = $q.defer()

        ReportConfigurationService
          .getReportConfiguration linkCfg.targetId
          .then (cfg) =>
            linkCfg.configuration = cfg

            for reportItem in @reportItems
              linkValue = @getFieldData(link.field, reportItem)

              params =
                configuration: cfg['_id']
                'filter[]': "#{link.filter},#{linkValue}"

              color = null

              if link.options? and link.options.mappable_group?
                seed = @getFieldData link.options.mappable_group, reportItem

                if seed?
                  color = ColorService.getColorBySeed seed

              @getLinkVisualizeFromBackend(params, cfg, reportItem)
            deferred.resolve()
          , () ->
            deferred.reject()

        promises.push deferred

        $q
          .all promises
          .then () =>
            @reloadMapLinkWaypoints()

    @getLinkVisualizeFromBackend = (params, configuration, reportItem) =>
      # Lock used visualizer to JSON
      params.visualizer = 'JSON'

      # Get source
      source = @reportSources.filter (v) -> v.code == configuration.source
      source = if source.length then source[0] else null

      # If our source was not found (eg: no permission), do nothing
      if not source?
        return

      # Get the mappable fields for the source
      # NB: we currently only support geo_points visualized as linestrings
      mappableFields = _.values source.fields
        .filter (f) ->
          return f.options? and f.options.mappable? and f.options.mappable and f.type == 'geo_point'

      # If we don't have mappable fields, do nothing
      if not mappableFields.length
        return

      # Visualize once with only mappable fields, to create a linestring for instance
      params['field[]'] = mappableFields.map (v) -> return v.field # Only select fields required for mapping
      params.limit = 2500 # Always get data in pieces of max 2.5k entries
      params.bypassFilters = true # Bypass non-locked filters to get a complete dataset

      RestUtils.getFullList ServiceReportVisualizeManager, params
        .then (results) =>
          coords = []

          for res in results
            # Iterate over mappable fields and try to get location data
            for field in mappableFields
              data = @getFieldData field.field, res

              if data?
                coords.push data.coordinates

          if coords.length && coords.length > 2
            # Add coords to link layer as linestring
            geometry = MundoMap.getGeometryFromGeoJSON
              type: 'LineString'
              coordinates: coords
            feature = new ol.Feature
              geometry: geometry
              _item: reportItem
            @linkLayerSource.addFeature feature

            # Add coords to heatmap layer as well
            geometry = MundoMap.getGeometryFromGeoJSON
              type: 'MultiPoint'
              coordinates: coords
            feature = new ol.Feature
              geometry: geometry
            @heatmapLayerSource.addFeature feature
          else
            toastr.info $translate.instant('app.report.report-map-not-enough-points'), '',
              extendedTimeOut: 0
              timeOut: 0

    # Get the data from backend
    @getData = (source, reload = true, emitToParent = false) =>

      # Check if the sourceFilter is already initiated
      if !@sourceFilters

        # Assign sourceFilters to the source its filters
        @sourceFilters = source.sourcefilters

        # Loop all the filters
        angular.forEach @sourceFilters, (sourceFilter) ->

          # Check the type of the filter
          # Will be changed to a SWITCH
          if sourceFilter.type == "choice"
            sourceFilter.selectedItems = []
            # Only do this if filters aren't array
            if sourceFilter.options.choices not instanceof Array
              # Convert the choices-param to an array
              # If it contains standard choices
              # Those will be converted into an array with format {id: 'id', value: 'value'}
              choices = angular.copy sourceFilter.options.choices
              sourceFilter.options.choices = []
              angular.forEach choices, (value, key) ->
                sourceFilter.options.choices.push {id: key, value: value}

      # Check if the source has a 'configuration' set to it.
      # If it has a configuration it is not a new one.
      # So no new configuration must be posted.
      if angular.isDefined source.configuration
        @setViewMode(@getUserDataValue('viewMode', ViewMode.TABLE, source.configuration), false)

        if !@map
          @setMapProperties source.configuration, emitToParent
        else
          visibleLayers = @getUserDataValue 'mapLayers', null
          if visibleLayers? && @map?
            MundoMap.setVisibleLayers @map, @getUserDataValue 'mapLayers'

          if reload
            # Getting the visualize from backend with the configuration.
            @getVisualizeDataFromBackend source.configuration, null, emitToParent
          else
            if emitToParent
              @tabAddedOnLoad()
      else
        # Post a new Configuration with some options.
        ReportConfigurationService.createReportConfiguration source,
          fields: @getReportFields()
          filters: @defaultFilters
          opened: true
        .then (createdConfig) =>
          @setViewMode(@getUserDataValue('viewMode', ViewMode.TABLE, createdConfig), false)

          # Emit the adding config to tab to MundoReportingCtrl.
          $scope.$emit 'addConfigToTabEmit', {'source': source, 'config': createdConfig}

          if !@map
            @setMapProperties createdConfig, emitToParent
          else
            visibleLayers = @getUserDataValue 'visibleLayers', null
            if visibleLayers? && @map?
              MundoMap.setVisibleLayers @map, @getUserDataValue 'mapLayers'
            if reload
              # Getting the visualize from backend with the configuration.
              @getVisualizeDataFromBackend createdConfig, null, emitToParent
            else
              if emitToParent
                @tabAddedOnLoad()

    @setMapProperties = (passedConfig, emitToParent = false) =>
      @getVisualizeDataFromBackend passedConfig, null, emitToParent

    @downloadButtonsPdfCsvFilter = (value) ->
      code = $filter('lowercase')(value.code)
      label = $filter('lowercase')(value.label)
      return (code.match /jasperphpxml$/) || code == 'csv' || label == 'xls'

    @downloadButtonsNotPdfCsvFilter = (value) ->
      code = $filter('lowercase')(value.code)
      label = $filter('lowercase')(value.label)
      return (!code.match /jasperphpxml$/) && code != 'csv' && label != 'xls'

    @downloadReport = (code) =>
      # save zoomlevel, mapcenter, viewmode, ...
      @saveUserData()

      if @downloadState != DownloadState.IDLE
        return

      @downloadState = DownloadState.ACTIVE

      params =
        download: true
        visualizer: code
        configuration: @config['_id']

      if @source.source.visualizeFilters
        angular.forEach @source.source.visualizeFilters, (vFilter, key) =>
          # Check in configurationFilters to add the value
          sourceFilters = @sourceFilters.filter (sf) ->
            sf.code == vFilter.code
          angular.forEach sourceFilters, (sf) ->
            if sf.locked
              sf.filterValue = vFilter.value
          params['filter[' + key + ']'] = vFilter.code + ',' + vFilter.value

      ServiceReportVisualizeDownloadManager.one().withHttpConfig
        responseType: 'arraybuffer'
      .get(params).then (response) =>
        @downloadState = DownloadState.IDLE

        FileSaver
          .saveFromResponse response
      , (error) =>
        @downloadState = DownloadState.IDLE

      return true

    @refreshVisualizedData = (reload = true) =>
      @reachedEndOfReportItems = false
      @getVisualizeDataFromBackend @source.configuration, reload

    # Method to get report data with a certain configuration.
    @getVisualizeDataFromBackend = (sourceConfig = @source.configuration, reload = true, emitToParent = false) =>
      @errorMessage = null
      @config = sourceConfig
      @isLoadingData = true

      # Remove all data from map
      if @markerLayerSource
        @markerLayerSource.clear()
      if @linkLayerSource
        @linkLayerSource.clear()
      if @embeddedLinkLayerSource
        @embeddedLinkLayerSource.clear()
      if @heatmapLayerSource
        @heatmapLayerSource.clear()

      if reload
        # Clear reportItems
        @reportItems = []

      # Lock sorting of table
      if @config.sort and @config.sort.length
        @table.orderField = @config.sort[0].field
        @table.orderDirection = @config.sort[0].direction

        @table.order = if @table.orderDirection then '' else '-'
        @table.order = "#{@table.order}#{@table.orderField}"

      params = {}
      if @source.source.visualizeFilters
        angular.forEach @source.source.visualizeFilters, (vFilter, key) =>
          # Check in configurationFilters to add the value
          sourceFilters = @sourceFilters.filter (sf) ->
            sf.code == vFilter.code
          angular.forEach sourceFilters, (sf) ->
            if sf.locked
              sf.filterValue = vFilter.value
          params['filter[' + key + ']'] = vFilter.code + ',' + vFilter.value
      # Doing the call to get the visualize data.
      params.visualizer = 'JSON'
      params.configuration = sourceConfig['_id']
      params.limit = if reload then @table.pageLimit + 1 else @table.pageLimit
      params.offset = @table.recordOffset

      ServiceReportVisualizeManager.getList(params)
      .then (reports) =>
        if reports.length >= params.limit
          @reachedEndOfReportItems = false
        else
          @reachedEndOfReportItems = true
          @table.pageCount = @table.pageNumber

        @isLoadingData = false

        if reload and (reports.length == params.limit)
          reports.pop()

        @reportItems = reports

        # Get the geo_points of the fields
        locationFields = _.filter @source.sourcefields, (v) -> v.type == 'geo_point'
        locationFields = if locationFields then angular.copy(locationFields) else []

        # Get point of item
        # Split the string
        # pointLocationList = if pointLocation then pointLocation.split('.') else []

        # Loop all reportItems
        angular.forEach @reportItems, (reportItem, key) =>
          index = key

          reportItem._meta =
            color: null
            mapMarkerUrl: null
            mapMarkerFeatures: []
            index: index

          # Determine marker
          if reportItem.unit? and reportItem.unit.id?
            reportItem._meta.mapMarkerUrl = MundoMap.getMarkerPath reportItem.unit.id

          # Iterate over location fields
          angular.forEach locationFields, (field) =>
            data = angular.copy reportItem

            # Determine color
            color = null

            if field.options? and field.options.mappable_group?
              seed = @getFieldData field.options.mappable_group, reportItem

              if seed?
                color = ColorService.getColorBySeed seed
            
            # siren = reportItem.io['io-digital-input-2'] if reportItem.io?
            # lights = reportItem.io['io-digital-input-1'] if reportItem.io?
            # if siren? and lights?
            #   color = '00ffd7'
            # else if siren?
            #   color = 'ffce00'
            # else if lights?
            #   color = '0054ae'

            if (not reportItem._meta.color?) and color?
              reportItem._meta.color = color

            # For each location-based field, get the point and create a geometry + feature
            location = @getFieldData(field.field, reportItem)

            if not location
              return

            # Determine label for marker
            label = @formatStringAsTemplate field.options.label, reportItem
            description = @formatStringAsTemplate field.options.description, reportItem
            geometry = MundoMap.getGeometryFromGeoJSON location

            feature = new ol.Feature
              geometry: geometry
              name: reportItem['_id']
            feature.setProperties
              _item: reportItem
              _itemIndex: index
              _label: label
              _description: description
              _color: color
              _field: field.code
              _tags: [
                reportItem.label
                label
              ]

            # Add feature to the layer
            @markerLayerSource.addFeature feature
            @heatmapLayerSource.addFeature feature

            # Store feature in report item
            reportItem._meta.mapMarkerFeatures.push feature

        if @viewMode == ViewMode.MAP
          @loadMappableLinks(true)

        @onResize()

        if not @reportItems.length
          @errorMessage = $translate.instant 'app.report.no-data-found'

        if emitToParent
          @tabAddedOnLoad()

      , (error) =>
        errorData =
          data: error.data
          window:
            href: window.location.href
            userAgent: window.navigator.userAgent
          user:
            id: $rootScope.user.id
            username: $rootScope.user.username
            email: $rootScope.user.email
          userContext: if $rootScope.user.activeUserContext != null and
            typeof $rootScope.user.activeUserContext == 'object' then {
            id: $rootScope.user.activeUserContext.id
          } else null
          tenant: if $rootScope.user.activeUserContext != null and
            typeof $rootScope.user.activeUserContext == 'object' then {
            id: $rootScope.user.activeUserContext.tenant.id
            code: $rootScope.user.activeUserContext.tenant.code
            label: $rootScope.user.activeUserContext.tenant.label
          } else null
          timestamp:
            ISO8601: (new Date()).toISOString()
            unix: Math.round((new Date()).getTime() / 1000)
          report:
            id: sourceConfig['_id']
        errorData = btoa(JSON.stringify(errorData)).match(/.{1,32}/g).join('<br>')

        @errorMessage = "#{$translate.instant('app.report.fetch-data-error')}<br>" +
          "#{$translate.instant('app.report.try-adding-more-filters')}<br>" +
          "#{$translate.instant('app.report.error-data-report')}<br>" +
          "<pre><code>#{errorData}</code></pre>"
        @isLoadingData = false

        if emitToParent
          @tabAddedOnLoad()

    ##############################################################################
    ################### REPORT CONFIGURATION DIALOG METHODS ######################
    ##############################################################################

    @setConfigurationPinned = =>
      @shouldBeSavedWithPinned = true
      @showConfigurationEditForm()

    # Show dialog form to edit configuration
    @showConfigurationEditForm = =>
      @copyOfSourceTitle = angular.copy @source.configuration.label
      @form = null
      @reportConfigurationFields = [
        key: 'reportLabel'
        name: 'reportLabel'
        type: 'input'
        defaultValue: @copyOfSourceTitle
        templateOptions:
          label: $filter('ucfirst')($translate.instant('app.report.report-name'))
          placeholder: $filter('ucfirst')($translate.instant('app.report.report-name-placeholder'))
          required: true
          focus: true
      ]

      if $rootScope.hasPermission 'manage report templates'
        saveAsTemplateField =
          key: 'template'
          name: 'template'
          type: 'checkbox'
          hideExpression: () ->
            $rootScope.hasPermission("manage report templates") == false
          templateOptions:
            label: $filter('ucfirst')($translate.instant('app.report.convert-to-template'))
            required: false
        @reportConfigurationFields.push saveAsTemplateField

      # Show Dialog
      $mdDialog.show
        focusOnOpen: false
        templateUrl: 'mundo-reporting/views/dialogs/report-details-dialog.modal.tpl.html'
        controller: =>
          this.parent = @
        controllerAs: 'parentCtrl'

    # Cancel
    @cancel = ->
      $mdDialog.cancel()
      @shouldBeSavedWithPinned = null

    @submit = =>
      @source.configuration.label = angular.copy @form.reportLabel['$modelValue']
      params = {'label': @source.configuration['label']}

      # save zoomlevel, mapcenter, viewmode, ...
      @saveUserData()

      if @shouldBeSavedWithPinned
        params['pinned'] = true
        @source.configuration.pinned = true

      # Save the label to the backend
      @source.configuration.label = angular.copy @form.reportLabel['$modelValue']
      ReportConfigurationService.patchConfiguration @source.configuration, params
        .then () =>
          @copyOfSourceTitle = null
          @shouldBeSavedWithPinned = null
          $mdDialog.hide()

          $scope.$emit 'replaceConfigWithUpdatedConfig', {
            'source': @source,
            'config': @source.configuration
          }

          if @form.template?
            template = @form.template['$modelValue']

            if template
              $rootScope.$emit 'configConversion', {
                configuration: @source.configuration
              }

    @tabAddedOnLoad = () ->
      $rootScope.$emit 'addTabOnLoadAdded', {
        finished: true
      }

    @reloadConfiguration = () =>
      @isLoadingData = true

      return $q (resolve, reject) =>
        ReportConfigurationService
          .getListWithParams {
            'filter[]': '_id,EQ ' + @source.configuration['_id']
          }
          .then (results) =>
            results[0].opened = @source.configuration['opened']
            @source.configuration = results[0]

            $scope.$emit 'replaceConfigWithUpdatedConfig', {
              'source': @source,
              'config': results[0]
            }

            # Get the data with the edited filter
            @getData @source, false
            resolve()
          , () ->
            reject()

    # Save Configuration
    @saveConfiguration = (parts = {}) =>
      @isLoadingData = true

      defaults =
        sort: false
        fields: false
        userData: false

      parts = angular.merge {}, defaults, parts
      changes = {}

      if parts.sort
        changes['sort'] = [
            field: @table.orderField
            direction: @table.orderDirection
        ]

      if parts.fields
        changes['fields'] = @getReportFields()

      if parts.userData
        changes['userData'] = @getUserData()

      $q (resolve, reject) =>
        ReportConfigurationService.patchConfiguration @source.configuration, changes
          .then () =>
            @reloadConfiguration().then (result) ->
              resolve result
            () ->
              reject()
          , () ->
            reject()

    @selectMainFilters = () =>
      ReportFilterService.selectFilters(@source.source, @source.configuration).then (result) =>
        @table.pageNumber = 1
        @table.pageCount = 2
        @table.recordOffset = (@table.pageNumber - 1) * @table.pageLimit
        @table.recordCount = (@table.pageNumber + 1) * @table.pageLimit

        @reloadConfiguration().then () =>
          @refreshVisualizedData()

    ##############################################################################
    ########################### DATATABLE METHODS ############################
    ##############################################################################

    @loadMoreData = =>
      @table.recordOffset += @table.pageLimit
      @getVisualizeDataFromBackend @source.configuration, false

    @loadPreviousPage = =>
      if @table.pageNumber == 1
        return

      @applyTablePagination (@table.pageNumber - 1)

    @setPage = (page) =>
      # Just set a page.
      @applyTablePagination (page)

    @loadNextPage = =>
      if @table.pageNumber == @table.pageCount
        return

      @applyTablePagination (@table.pageNumber + 1)

    @formatStringAsTemplate = (templateSource, value, field) ->
      val = Mustache.render templateSource,
        value: value
      return val

    @formatStringAsHtml = (formattedValue) =>
      # return formattedValue
      formattedValue = $sce.trustAsHtml(formattedValue)
      $compile(formattedValue)(@)
      return formattedValue

    @getPagerItems = () =>
      linkCount = 6
      rest = 0
      numbers = []

      # Get start and end
      start = @table.pageNumber - (linkCount / 2)
      end = @table.pageNumber + 1 + (linkCount / 2)

      if(start < 1)
        rest = start
        start = 1
        end = end - rest

      i = start
      while i < end
        numbers.push i
        i++

      return numbers

    @getFirstFilledInMultiFieldData = (fieldsArray, data, key = 0) =>
      if key < fieldsArray.length
        value = @getFieldData(fieldsArray[key], data)
        if value
          # Get the field for the key
          field = @getFieldForCode fieldsArray[key]
          return { value: value, field: field }
        else
          return @getFirstFilledInMultiFieldData fieldsArray, data, (key + 1)
      else
        return null

    @getFieldForCode = (fieldCode) =>
      foundFields = _.filter @source.sourcefields, (field) ->
        field.code == fieldCode
      if (foundFields && angular.isArray(foundFields) && foundFields.length > 0)
        return foundFields[0]
      else
        return null

    @getMultiFieldData = (field, data) =>
      if not field.options.fields?
        return null

      if field.options && field.options.multiple
        fields = []
        angular.forEach field.options.fields, (subField) =>
          value = @getFieldData(subField, data)
          fields.push { value: value, field: @getFieldForCode subField }
        return fields
      else
        # Get first value of field.field
        if angular.isArray(field.options.fields)
          return @getFirstFilledInMultiFieldData field.options.fields, data
        else
          return null

    @applyTableSorting = (order) =>
      if order[0] == '-'
        @table.orderDirection = false
        @table.orderField = order.substr(1)
      else
        @table.orderDirection = true
        @table.orderField = order

      @saveConfiguration
        sort: true
      .then () =>
        @refreshVisualizedData()

    @applyTablePagination = (page = @table.pageNumber, limit = @table.pageLimit) =>
      @table.pageNumber = Number(page)
      @table.pageLimit = Number(limit)

      @table.recordOffset = (@table.pageNumber - 1) * @table.pageLimit
      @table.recordCount = (@table.pageNumber + 1) * @table.pageLimit
      @table.pageCount = @table.pageNumber + 1

      @refreshVisualizedData()

    # Method to get the right value of a certain key for a row in the datatable.
    @getFieldData = (path, returnData, delimiter = '.', wildcard = '*', needsCompleteReturn = false, filter = false) ->
      if (needsCompleteReturn) || (path.length == 0)
        return returnData

      # Split the key to go as deep as possible in the object/dictionary.
      parts = path.split delimiter

      for part, key in parts
        if part == '*'
          if angular.isObject returnData

            tempPath = (parts.slice(key + 1)).join delimiter
            path = (parts.slice(0, key)).join delimiter
            if angular.isArray returnData
              tempResult = []
              for subData, jKey in returnData
                if tempPath && tempPath.length > 0
                  tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard)
                else
                  tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard, true, filter)
              returnData = tempResult
            else
              tempResult = {}
              for jKey, subData of returnData
                tempResult[jKey] = @getFieldData(tempPath, subData, delimiter, wildcard, true, filter)
              returnData = tempResult
            break
          else
            break
        else
          try
            if angular.isDefined returnData[part]
              returnData = returnData[part]
            else
              returnData = null
              break
          catch
            returnData = null

      if filter and angular.isArray(returnData)
        returnData = returnData.filter (v) -> v != null

      return returnData

    # Filter the columns where the source has an option 'field' with value TRUE.
    @filteredColumns = (element) ->
      if element['options']['field'] && element['options']['field'] == true
        true
      else
        false

    # Get field template path
    @getFieldTemplatePath = (field, raw = false) ->
      mode = null
      if raw
        mode = 'element'
      else if field.options and field.options.multiple
        mode = 'multiple'
      else
        mode = 'single'

      if (field && field.type)
        type = field.type
      else
        type = 'text'

      "mundo-reporting/views/table/field/#{type}/#{mode}.tpl.html"

    @reloadFieldTemplateHtml = (fields) ->
      promises = []

      for field in fields
        promises.push $templateRequest(@getFieldTemplatePath(field))

      return $q.all(promises)

    @triggerLink = (link, reportItem, value) =>
      cfgLink = @source.configuration.links
        .filter (v) ->
          link.code == v.code
      cfgLink = if cfgLink.length then cfgLink[0] else null

      if link.options? and link.options.display_field? and link.options.display_field
        value = @getFieldData(link.field, reportItem)

      # Get Configuration from backend
      ReportConfigurationService.getReportConfiguration cfgLink.targetId
        .then (linkedCfg) ->
          if not linkedCfg
            return

          parentCtrl = $scope.$parent.$parent.reportingCtrl
          source = parentCtrl.reportSources.filter (v) ->
            v.code == linkedCfg.source
          source = if source.length then source[0] else null
          source = angular.copy source
          # temporary
          source.configuration = linkedCfg
          # Grab all filters
          filters = link.filter
          filters = if angular.isArray filters then filters else [filters]
          appliedFilters = []

          for filter in filters
            filterValue = value
            filterValue = if filter.options && filter.options.multiple then [filter_value] else filterValue
            appliedFilters.push
              code: filter
              operand: 'EQ'
              value: filterValue

          parentCtrl.addNewTab source, appliedFilters

    @openDialog = (evt, options) ->
      defaults =
        body: null
        title: 'app.view'
        onConfirm: () ->
        onCancel: () ->
        template: null

      options = angular.merge {}, defaults, options
      @searchfilter = ''
      $mdDialog
        .show
          ### @ngInject ###
          controller: ($mdDialog, $scope) =>
            $scope.title = options.title
            $scope.body = options.body
            $scope.parentCtrl = @

            $scope.hide = () ->
              $mdDialog.hide()
            $scope.cancel = () ->
              $mdDialog.cancel()
            $scope.confirm = (result) ->
              $mdDialog.hide result
          templateUrl: options.template
          parent: angular.element document.body
          targetEvent: evt
          clickOutsideToClose: true
          fullscreen: false
          focusOnOpen: false
        .then (result) ->
          options.onConfirm result
        , () ->
          options.onCancel()

    @getIoClasses = (io) ->
      classes = []
      classes.push (if io && io.value then 'io-active' else 'io-inactive')

      if io && io.tags?
        (classes.push "io-tag-#{x}" for x in io.tags)

      classes

    # Resize the table to the page
    @setTableHeight = () ->
      setTimeout () ->
        for x in document.querySelectorAll 'md-tab-content'
          tab = angular.element x
          table = x.querySelectorAll 'table.md-table'

          if (not table?) or (not table.length)
            continue

          # Scroll to top
          window.scrollTo 0, 0

          container = angular.element(table[0]).parent().parent()

          # Add class
          container.addClass 'report-table-container'

          # Determine viewport height
          viewportHeight = window.innerHeight
          # Determine offset from top
          topOffset = container[0].getBoundingClientRect().top

          # Determine footer height
          footerHeight = tab[0].querySelectorAll('.report-table-footer')[0].offsetHeight
          # Subtract additional margins, etc..
          additionalHeight = 10

          # Determine maximum table height
          tableMaxHeight = viewportHeight - topOffset - footerHeight - additionalHeight
          # Ensure table height never drops below the minimum value
          tableMinimumHeight = 500
          tableMaxHeight = if tableMaxHeight < tableMinimumHeight then tableMinimumHeight else tableMaxHeight

          # Set max-height
          container.css 'max-height', "#{tableMaxHeight}px"
          # Set min-height also
          container.css 'min-height', "#{tableMaxHeight - 5}px"

      , 250

    ###########
    # Actions #
    ###########

    @getValidActions = (reportItem) =>
      sourceActions = @source.source.actions
      actions = []

      angular.forEach sourceActions, (action, code) =>
        data = @getFieldData action.field, reportItem

        if not data?
          return

        if action.options? and action.options.unit_permission?
          if not UserPermissions.check(action.options.unit_permission)
            UnitPermissions.permissionsLoaded().then () ->
              if UnitPermissions.check(action.options.unit_permission, data)
                action = angular.copy action
                action.value = data
                actions.push action
            return

        action = angular.copy action
        action.value = data
        actions.push action

      return actions

    @executeAction = (action, reportItem) ->
      if action.code in []
        MundoActions.execute action.code
      else
        MundoActions.execute action.code,
          data:
            id: action.value

    ##########
    # Fields #
    ##########

    @getSourceFields = ReportFieldService.getSourceFields

    @initializeFields = () ->
      fields = []
      sourceFields = @getSourceFields(@source.source)

      # Check if the configuration has fields configured and if they are all valid
      if @source.configuration? and @source.configuration.fields? and @source.configuration.fields.length
        for f in @source.configuration.fields
          for v in sourceFields
            if (f.field == v.field) and v.options? and v.options.field
              fields.push v

      @setFields fields

    @setFields = (fields) ->
      # If the fields array is empty, set the default filters.
      # else just continue setting fields to fields
      if not (fields? and fields.length)
        fields = []
        for field in @defaultFields
          for v in @getSourceFields(@source.source)
            if v.code == field
              fields.push v
              break

      @fields = fields

      # Reset row html to match fields
      @reloadFieldTemplateHtml(@fields).then (fieldHtml) =>
        @fieldHtml = fieldHtml

    @loadDefaultFields = () ->
      return $q (resolve, reject) =>
        MundoSettings
          .get "reports.#{@source.source.code}.fields.default"
          .then (fields) =>
            @defaultFields = fields
            resolve()
          , () =>
            @defaultFields = (
              x.code for x in @getSourceFields(@source.source) when not
              (x.options? and x.options.default? and (not x.options.default))
            )
            resolve()

    @setDefaultFields = (fields) ->
      return $q (resolve, reject) =>
        if not (fields? and fields.length)
          fields = @getSourceFields(@source.source)
          fields = (x.code for x in fields)

          MundoSettings
            .remove "reports.#{@source.source.code}.fields.default"
            .then () =>
              @defaultFields = fields
              resolve()
            , () ->
              reject()
        else
          fields = (x.code for x in fields)
          MundoSettings
            .set "reports.#{@source.source.code}.fields.default", fields
            .then () =>
              @defaultFields = fields
              resolve()
            , () ->
              reject()

    @getReportFields = (source = @source.source, fields = @fields) ->
      # Append the non-table fields to the list of selected fields.
      # In the future we may detect which non-table fields to use
      # based on whether or not they're used in templates/etc..,
      # but for now we just select all of them.
      extraFields = (_.values source.fields).filter (v) -> not (v.options? and v.options.field)
      fields = fields.concat extraFields
      fields = fields
        .filter (v) -> !angular.isArray v.field
        .map (v) -> { field: v.field, label: null }

      return fields

    @selectFields = () =>
      fields = @getSourceFields(@source.source)
      selected = angular.copy @fields
      state ={progress: false}

      fields.forEach (f) ->
        f._selected = false

        selected.forEach (s) ->
          if s.code == f.code
            f._selected = true

      UiHelpers.openDialog
        template: 'mundo-reporting/views/dialogs/report-field-selection-dialog.tpl.html'
        title: 'app.report.columns'
        onConfirm: (result) =>
          @setFields selected

          @saveConfiguration
            fields: true
          .then () =>
            @refreshVisualizedData()
        data:
          fields: fields
          selected: selected
          state: state
          toggleField: (field) ->
            # Always assume inverse values for
            # field._selected, since ng-model
            # is triggered BEFORE ng-change
            if not field._selected
              for v, k in selected
                if v? and v.code == field.code
                  selected.splice k, 1
            else
              selected.push field

            # If this field is being selected and it is
            # not compatible with other fields, deselect
            # other incompatible fields
            if field._selected
              if field.options? and field.options.exclude?
                regex = new RegExp(field.options.exclude)
                for v, k in angular.copy selected
                  if v? and v.code? and v.code.match regex
                    for w, l in selected when (w? and w.code? and (w.code == v.code))
                      selected.splice l, 1
                      w._selected = false
                    for w, l in fields when (w? and w.code? and (w.code == v.code))
                      w._selected = false
          canSetAsDefault: () ->
            return UserPermissions.check 'manage all MundoMosaSettingsBundle:Setting entities'
          setAsDefault: () =>
            state.progress = true
            @setDefaultFields selected
            .then () ->
              state.progress = false
            , () ->
              state.progress = false

    ###########
    # Filters #
    ###########

    @getFilterList = (source = @source.source) ->
      filters = _.values source.filters
      filters = angular.copy filters

      return filters

    @loadDefaultFilters = () ->
      return $q (resolve, reject) =>
        MundoSettings
          .get "reports.#{@source.source.code}.filters.default"
          .then (filters) =>
            @defaultFilters = []
            filterList = @getFilterList()

            for v in filters
              for f in filterList
                if f.code == v.code
                  @defaultFilters.push v
                  break

            resolve()
          , () =>
            @defaultFilters = []
            resolve()

    @setDefaultFilters = (filters) ->
      return $q (resolve, reject) =>
        if not (filters? and filters.length)
          MundoSettings
            .remove "reports.#{@source.source.code}.filters.default"
            .then () =>
              @defaultFilters = []
              resolve()
            , () ->
              reject()
        else
          MundoSettings
            .set "reports.#{@source.source.code}.filters.default", filters
            .then () =>
              @defaultFilters = filters
              resolve()
            , () ->
              reject()

    @openInNewTab = (reportItem) =>
      # This reportItem is a session.
      # We should open a new session report with this is an extra filter
      session = reportItem._id

      source = @reportSources.filter (v) -> v.code == "MongoUnitMessage"
      source = if source.length then source[0] else null

      cfgLink = @source.configuration.links.filter (v) -> v.code == "MongoUnitMessage"
      cfgLink = if cfgLink.length then cfgLink[0] else null

      config = cfgLink.configuration
      filters = []
      angular.forEach config.filters, (filter) ->
        if filter.locked?
          delete filter.locked
        if filter.code == "sessions.id"
          filter.value = session

      # Search for timestamp filters. created_abs, created_rel
      dateFilters = _.filter config.filters, (filter) ->
        $log.debug filter.code
        ((filter.code == "timestamp.created_abs") || (filter.code == "timestamp.created_rel"))

      if (dateFilters.length == 0)
        dateFilter =
          code: "timestamp.created_abs"
          operand: "UNDEFINED"
          value: [
            "1990-10-16 11:34:00"
            "2090-10-16 11:34:00"
          ]
        config.filters.push dateFilter

      ReportConfigurationManager.post(
        label: "Session detail"
        opened: true
        pinned: false
        source: "MongoUnitMessage"
        filters: config.filters
      ).then (configuration) ->
        $scope.$emit 'openConfiguration',
          config: configuration

    toggleSidebar = () =>
      @sidebarVisible = !@sidebarVisible
      $timeout () =>
        @map.updateSize()
      , 20

angular
  .module('mundoReporting')
  .controller 'ReportDataCtrl', ReportDataCtrl
